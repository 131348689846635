import { WidgetConfiguration } from "@sgwt-widget/core";
import { ajaxCall } from "./AjaxService";

export const ERROR_MISSING_URL = "Missing E-Sign service URL";

export async function buildDocumentUrl(
  widgetConfiguration: WidgetConfiguration,
  transactionId: number,
  documentId: number
): Promise<string> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<
    ISignatureConfig
  >()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject(ERROR_MISSING_URL);
  }
  // const publicRoute = isPublicRoute() ? "v2/public" : "v2";
  // return `${serviceUrl}/${publicRoute}/transactions/${transactionId}/documents/${documentId}/content`;
  return `${serviceUrl}/v2/public/transactions/${transactionId}/documents/${documentId}/content`;
}

export async function buildPocUrl(
  widgetConfiguration: WidgetConfiguration,
  documentId: number
): Promise<string> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<
    ISignatureConfig
  >()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject(ERROR_MISSING_URL);
  }
  const publicRoute = isPublicRoute() ? "public/" : "";
  return `${serviceUrl}/rest/${publicRoute}proofOfConsent/downloadDocuments/${documentId}`;
}

function isPublicRoute(): boolean {
  const currentUrl: string = window.location.href;
  return currentUrl.includes("/p/");
}

export async function getDocumentForDownload(
  widgetConfiguration: WidgetConfiguration,
  documentUrl: string
): Promise<any> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<
    ISignatureConfig
  >()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject(ERROR_MISSING_URL);
  }
  return ajaxCall(
    {
      method: "GET",
      responseType: "blob",
      url: documentUrl,
      crossDomain: true,
    },
    widgetConfiguration
  )
    .map(response => response.response)
    .toPromise();
}
