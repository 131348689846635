import {WidgetConfiguration} from "@sgwt-widget/core";
import {IChannel} from "../domain/IChannel";
import {ITransaction} from "../domain/ITransaction";
import {ajaxCall} from "./AjaxService";
import { ERROR_MISSING_URL } from "./DocumentService";

const APPLICATION_JSON = "application/json";

export async function sign(widgetConfiguration: WidgetConfiguration,
                           transactionId: number,
                           channel: IChannel,
                           language: string): Promise<ITransaction> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject(ERROR_MISSING_URL);
  }
  return ajaxCall({
    body: channel,
    headers: {"Content-Type": APPLICATION_JSON},
    method: "POST",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/v2/transactions/${transactionId}/sign?lang=${language}`,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}

export async function hasSigned(widgetConfiguration: WidgetConfiguration,
                                transactionId: number,
                                language: string): Promise<ITransaction> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject(ERROR_MISSING_URL);
  }
  return ajaxCall({
    headers: {"Content-Type": APPLICATION_JSON},
    method: "POST",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/rest/transaction/${transactionId}/hasSigned?lang=${language}`,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}

export async function reject(widgetConfiguration: WidgetConfiguration,
                             transactionId: number,
                             language: string): Promise<ITransaction> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject(ERROR_MISSING_URL);
  }
  return ajaxCall({
    headers: {"Content-Type": APPLICATION_JSON},
    method: "POST",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/rest/transaction/${transactionId}/cancel?lang=${language}`,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}

export async function validateCorporateSignature(widgetConfiguration: WidgetConfiguration,
                                                 transaction: ITransaction,
                                                 language: string): Promise<ITransaction> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject(ERROR_MISSING_URL);
  }
  return ajaxCall({
    headers: {"Content-Type": APPLICATION_JSON},
    method: "POST",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/v2/transactions/${transaction.id}/validate?lang=${language}`,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}

export async function rejectCorporateSignature(widgetConfiguration: WidgetConfiguration,
                                               transaction: ITransaction,
                                               language: string): Promise<ITransaction> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject(ERROR_MISSING_URL);
  }
  return ajaxCall({
    headers: {"Content-Type": APPLICATION_JSON},
    method: "POST",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/rest/transaction/${transaction.id}/reject?lang=${language}`,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}
