import { WidgetConfiguration } from "@sgwt-widget/core";
import { ajaxCall } from "./AjaxService";
import { ERROR_MISSING_URL } from "./DocumentService";

export async function getQualifiedSignatureUrl(widgetConfiguration: WidgetConfiguration): Promise<string> {
  return (await widgetConfiguration.myConfiguration<ISignatureConfig>()).qualifiedSignatureUrl;
}

export async function getSignatureId(
  widgetConfiguration: WidgetConfiguration,
  transactionId: number
): Promise<string> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<
    ISignatureConfig
  >()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject(ERROR_MISSING_URL);
  }
  return ajaxCall({
    method: "GET",
    responseType: "json",
    url: `${serviceUrl}/rest/luxtrust/${transactionId}/signatureId`,
    crossDomain: true,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}

export async function getCurrentUserToken(
  widgetConfiguration: WidgetConfiguration
): Promise<string> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<
    ISignatureConfig
  >()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject(ERROR_MISSING_URL);
  }
  return ajaxCall({
    method: "GET",
    responseType: "text",
    url: `${serviceUrl}/rest/luxtrust/currentUserToken`,
    crossDomain: true,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}

export async function getSignatureIframe(
  widgetConfiguration: WidgetConfiguration,
  url: string,
  token: string
): Promise<any> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<
    ISignatureConfig
  >()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject(ERROR_MISSING_URL);
  }
  return ajaxCall({
    method: "GET",
    responseType: "text",
    headers: { "Authorization": "Bearer " + token, "Accept": "application/json, text/plain, */*" },
    url,
    crossDomain: true,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}

