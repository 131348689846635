import "./SignaturePage.scss";
import { IWidgetConfigurationProps, withWidgetConfiguration, } from "@sgwt-widget/core";
import { Component, h } from "preact";
import { MarkupText } from "preact-i18n";
import { connect } from "preact-redux";
import { Action } from "redux";
import { OtpMode } from "../../../common/domain/IOtp";
import { ISignatureCounterpart } from '../../../common/domain/ISignatureCounterpart';
import { INTERMEDIATE_VALIDATOR, ITransactionValidator } from '../../../common/domain/ITransactionValidator';
import { IUser } from "../../../common/domain/User";
import { getCurrentUser, isUserValidValidator, } from "../../../common/services/UserService";
import { exitFullscreenMode } from "../../actions/IDocumentAction";
import { forwardToSignatories, rejectContract } from '../../actions/IIntermediateValidatorAction';
import { reject } from "../../actions/IRejectionAction";
import {
  errorConsent,
  initWorkflow,
  setIsPublicSignatory,
  setOTPMode,
  setSignatureWorkflow,
  sign,
  signWithoutOtp,
  updateStep
} from "../../actions/ISignatureAction";
import { ApplicationState } from "../../states/ApplicationState";
import { FullscreenMode } from "../../states/IDocumentState";
import { css } from "../../styles";
import ConfirmPhoneModal from "../confirm-phone-modal/ConfirmPhoneModal";
import ConfirmRejectionModal from "../confirm-rejection-modal/ConfirmRejectionModal";
import Checkbox from "../checkbox/Checkbox";
import ContractsReview from "../contract-review/ContractsReview";
import OtpModal from "../otp-modal/OtpModal";
import OverallContractsReview from "../overall-contracts-review/OverallContractsReview";
import SignatoriesBlock from "../signatories-block/SignatoriesBlock";
import { ITransaction } from "../../../common/domain/ITransaction";
import { confirmValidation } from "../../actions/IValidatorAction";
import CorporateSignatureModal from "../corporate-signature-modal/CorporateSignatureModal";
import SignatureWithoutOtpModal from "../signature-without-otp-modal/SignatureWithoutOtpModal";
import QualifiedOtpModal from "../qualified-otp-modal/QualifiedOtpModal";
import { IProofOfConsent } from "../../../common/domain/IProofOfConsent";
import { ISignatureWorkflow } from "../../../common/domain/ISignatureWorkflow";
import Otp from "../otp/Otp";
import {
  getContractName,
  getSignatureWorkflowByLanguage,
  hasDocumentViewer
} from "../../../common/services/SignatureWorkflow";
import WorkflowPagination from "../signature-workflow/WorkflowPagination";
import WorkflowDocumentList from "../signature-workflow/WorkflowDocumentList";
import WorkflowText from "../signature-workflow/WorkflowText";
import WorkflowBullets from "../signature-workflow/WorkflowBullets";
import { Alert } from "../../components/alert/Alert";
import WorkflowDownloadDocumentList from "../signature-workflow/WorkflowDownloadDocumentList";
import {
  getCurrentSignatoryOTPMethod,
  getCurrentSignatoryPhoneNumber,
  getOtpValue,
} from "../../../common/helpers/otpHelper";
import { DocumentViewerComponent } from "./document-viewer-component/DocumentViewerComponent";
import { PaginationComponent } from "./signature-component/pagination-component/PaginationComponent";
import { SignButtonsComponent } from "./signature-component/sign-buttons-component/SignButtonsComponent";
import { CancelSignatureComponent } from "./signature-component/cancel-signature-component/CancelSignatureComponent";
import { SignatureComponent } from "./signature-component/SignatureComponent";
import { isTransactionExpired, isTransactionSigned } from "../../../common/helpers/transactionHelper";

const PROOF_OF_CONSENT = 'Proof of consent';

interface IState {
  documentUrl: string;
  isFullscreenEnabled: boolean;
  allDocumentsRead: boolean;
  signatureProcessing: boolean;
  currentUser: IUser;
  consent: string,
  consents: Map<number, string[]>;
  step: number;
  signatureWorkflow: ISignatureWorkflow;
}

export interface ISignaturePageProps {
  language: string;
  documentsCount: number;
  enableFullscreen: boolean;
  isValidSignatory: boolean;
  isValidIntermediateValidator: boolean;
  allDocumentsRead: boolean;
  signatureProcessing: boolean;
  signable: boolean;
  transactionSigned: boolean;
  phoneNumber: string;
  isPocSigned: boolean;
  pocId: number;
  transaction: ITransaction;
  displayProofOfConsent: IProofOfConsent;
  isValidValidator: boolean;
  isTransactionRejected: boolean;
  isOtpMandatory: boolean;
  consent: string,
  consents: Map<number, string[]>;
  step: number;
  signatureWorkflow: ISignatureWorkflow;
  isPublicSignatory: boolean;
  otpMode: OtpMode;
  user: IUser;
  exitFullscreen: () => void;
  sign: (otpMode: OtpMode, phoneNumberIsValid: boolean) => void;
  reject: () => void;
  rejectByIntermediateValidator: () => void;
  forwardToSignatories: () => void;
  confirmValidation: (open: boolean, isConfirmed: boolean) => void
  signWithoutOtp: (isSigning: boolean) => void;
  errorConsent: (isInError: boolean) => void;
  updateStep: (step: number) => void;
  setSignatureWorkflow: (signatureWorkflow: ISignatureWorkflow) => void;
  initWorkflow: () => void;
  setConsent: (step: number, consent: string) => void;
  setIsPublicSignatory: (isPublicSignatory: boolean) => void;
  setOTPMode: (otpMethod: OtpMode) => void;
}

const SignaturePage = withWidgetConfiguration(
  class extends Component<ISignaturePageProps & IWidgetConfigurationProps, IState> {
    public documentContainer: HTMLElement;
    public isAlertDisplayed: boolean;
    public contractName: HTMLElement;
    public consent: HTMLElement;
    public cssColOffset = "col offset-lg-3";

    public async componentDidMount() {
      const currentUser: IUser = await getCurrentUser(
        this.props.widgetConfiguration
      );
      this.setState({ 
        currentUser,
        consents: new Map<number, string[]>()
      });
      this.props.updateStep(0);
      this.addFullscreenListener();
    }

    public addFullscreenListener() {
      [
        "fullscreenchange",
        "webkitfullscreenchange",
        "mozfullscreenchange",
        "msfullscreenchange",
      ].forEach((eventType: string) =>
        document.addEventListener(
          eventType,
          this.fullscreenChanged.bind(this),
          false
        )
      );
    }

    public async componentWillMount() {
      this.props.initWorkflow();
    }

    private setDocumentContainer(elt: HTMLElement) {
      this.documentContainer = elt;
    }

    public async componentWillReceiveProps(nextProps: ISignaturePageProps) {
      this.setState({
        allDocumentsRead: nextProps.allDocumentsRead,
        signatureProcessing: nextProps.signatureProcessing,
        consent: nextProps.consent,
        consents: nextProps.consents,
        step: nextProps.step,
        signatureWorkflow: nextProps.signatureWorkflow,
      });

      if (nextProps.enableFullscreen && this.state.isFullscreenEnabled !== nextProps.enableFullscreen) {
        this.fullScreen();
      } else if (!nextProps.enableFullscreen && this.state.isFullscreenEnabled !== nextProps.enableFullscreen) {
        this.exitFullScreen();
      }
    }

    public fullScreen() {
      const element: any = this.documentContainer;
      if (element) {
        (
          element.requestFullscreen ||
          element.webkitRequestFullscreen ||
          element.mozRequestFullScreen ||
          element.msRequestFullscreen
        ).call(element);
      }
    }

    public exitFullScreen() {
      const doc: Document = document;
      if (!doc.activeElement || !(document as any).fullscreenElement) {
        return;
      }
      (
        doc.exitFullscreen ||
        doc.webkitExitFullscreen ||
        (doc as any).mozCancelFullScreen ||
        (doc as any).msExitFullscreen
      ).call(doc);
    }

    public fullscreenChanged(event: any) {
      if (this.state.isFullscreenEnabled) {
        this.setState({ isFullscreenEnabled: false });
        this.props.exitFullscreen();
      } else {
        this.setState({ isFullscreenEnabled: true });
      }
    }

    public async componentWillUnmount() {
      [
        "fullscreenchange",
        "webkitfullscreenchange",
        "mozfullscreenchange",
        "msfullscreenchange",
      ].forEach((eventType: string) => document.removeEventListener(eventType, this.fullscreenChanged));
    }

    public sign(otpMode: OtpMode, phoneNumberIsValid: boolean = false): void {
      if (this.state.allDocumentsRead && !this.state.signatureProcessing) {
        this.props.sign(otpMode, phoneNumberIsValid);
      } else {
        this.props.errorConsent(true);
      }
    }

    private isTransactionValid(): boolean {
      return this.props.signable
        && (!this.props.transaction.deadline || new Date(this.props.transaction.deadline).getTime() > new Date().getTime())
        && (this.props.isValidSignatory || !this.props.isPocSigned)
        && !this.props.transactionSigned
    }

    private isSignatureComponentDisplayed(): boolean {
      return this.isTransactionValid()
        && this.props.isOtpMandatory
        && this.state.signatureWorkflow == undefined
    }

    public renderComponentsForCorporateValidation(): JSX.Element[] {
      if (
        this.validatorExists()
        && this.props.isValidValidator
        && !this.props.transactionSigned
        && (!this.props.transaction.deadline || new Date(this.props.transaction.deadline).getTime() > new Date().getTime())
      ) {
        return [
          <section className={css("row mt-2")}>
            <PaginationComponent documentsCount={this.props.documentsCount}/>
          </section>,
          <section className={css("row")}>
            <section className={css(this.cssColOffset)}>
              <div className={css("row")}>
                <div className={css("col d-inline-flex justify-content-center")}>
                  <button className={css("btn btn-success", this.props.signable ? "" : "disabled")}
                    onClick={() => this.corporateSignature()} disabled={!this.props.signable}>
                    <MarkupText id={this.props.language + ".signature.corpo validation"}>
                      Sign contract
                    </MarkupText>
                  </button>
                  <button className={css("btn btn-default ml-3", this.props.signable ? "" : "disabled")}
                    onClick={() => this.rejectCorporateSignature()} disabled={!this.props.signable}>
                    <MarkupText id={this.props.language + ".signature.reject validation"}>
                      Reject contract
                    </MarkupText>
                  </button>
                </div>
              </div>
            </section>
          </section>,
          <CorporateSignatureModal />
        ]
      }
      return [];
    }

    private validatorExists() {
      return this.props.transaction && this.props.transaction.signatureCounterparts && this.getValidatorsFlattened();
    }

    private getValidatorsFlattened() {
      return this.props.transaction.signatureCounterparts
        .reduce((collector: ITransactionValidator[], { counterpartValidators }: ISignatureCounterpart) => ([...collector, ...counterpartValidators]), []);
    }

    private rejectCorporateSignature() {
      this.props.confirmValidation(true, false);
    }

    private corporateSignature() {
      this.props.confirmValidation(true, true);
    }

    private signWithoutOtp() {
      this.props.signWithoutOtp(true);
    }

    private isPublicSignatory(): void {
      if(this.props.transaction) {
        const currentCounterpartOrder = this.props.transaction.currentCounterpartOrder;
        const currentCounterpart = currentCounterpartOrder && this.props.transaction.signatureCounterparts.find((counterpart) => currentCounterpartOrder === counterpart.order);
        this.props.setIsPublicSignatory(currentCounterpart && ["LOCAL_SIGNER", "LAWYER", "SIGNATORY"].includes(currentCounterpart.type) || false);
      }
    }

    public renderIntermediateValidatorComponents(): JSX.Element[] | undefined {
      const isValidIntermediateValidator = isUserValidValidator(this.state.currentUser, this.props.transaction, INTERMEDIATE_VALIDATOR);
      if (
        isValidIntermediateValidator
        && !this.props.isTransactionRejected
        && (!this.props.transaction.deadline || new Date(this.props.transaction.deadline).getTime() > new Date().getTime())
      ) {
        return [
          <section className={css("row mt-4")}>
            <section className={css("col offset-lg-3 d-inline-flex justify-content-center")}>
              <button className={css("btn btn-lg btn-info")}
                onClick={() => this.props.forwardToSignatories()}>
                <MarkupText id={this.props.language + ".validator.forward"}>
                  Forward to signatories
                </MarkupText>
              </button>
              <button className={css("btn btn-lg btn-danger ml-3")}
                onClick={() => this.props.rejectByIntermediateValidator()}>
                <MarkupText id={this.props.language + ".validator.reject"}>
                  Reject contract
                </MarkupText>
              </button>
            </section>
          </section>,
          <ConfirmRejectionModal />];
      }
      return;
    }

    public renderModals(): JSX.Element[] {
      if (this.isSignatureComponentDisplayed()) {
        return [
          <OtpModal />,
          <QualifiedOtpModal />,
          <ConfirmPhoneModal />,
          <ConfirmRejectionModal />,
        ];
      }
      return [];
    }

    private renderExpiredMessage(): JSX.Element[] {
      if (isTransactionExpired(this.props.transaction)) {
        return [
          <section className={css("row mt-5")}>
            <section className={css(this.cssColOffset)}>
              <section className={css("row")}>
                <section className={css("col d-flex align-items-center flex-column")}>
                  <MarkupText id={this.props.language + ".signature.expired"}>
                    You cannot sign this document because deadline has been reached.
                  </MarkupText>
                </section>
              </section>
            </section>
          </section>
        ];
      }
      return [];
    }

    private renderComponentsForSignatureWithoutOtp(): JSX.Element[] {
      if (
        this.props.signable && this.props.isValidSignatory
        && !this.props.transactionSigned
        && (!this.props.transaction.deadline || new Date(this.props.transaction.deadline).getTime() > new Date().getTime())
        && !this.props.isOtpMandatory
      ) {
        return [
          <section className={css("row")}>
            <section className={css("col-3")}>
              <OverallContractsReview />
            </section>
            <PaginationComponent documentsCount={this.props.documentsCount}/>
          </section>,
          <section className={css("row")}>
            <section className={css(this.cssColOffset)}>
              <div className={css("row")}>
                <div className={css("col d-inline-flex justify-content-center")}>
                  <button className={css("btn btn-success", this.props.signable ? "" : "disabled")}
                    onClick={() => this.signWithoutOtp()} disabled={!this.props.signable}>
                    <MarkupText id={this.props.language + ".signature.sign"}>
                      Sign contract
                    </MarkupText>
                  </button>
                  <button className={css("btn btn-default ml-3", this.props.signable ? "" : "disabled")}
                    onClick={() => this.props.reject()} disabled={!this.props.signable}>
                    <MarkupText id={this.props.language + ".signature.reject-without-otp"}>
                      Reject contract
                    </MarkupText>
                  </button>
                </div>
              </div>
            </section>
          </section>,
          <ConfirmRejectionModal />,
          <SignatureWithoutOtpModal />
        ]
      }
      return [];
    }

    public renderSignatoriesAndContractReview(): JSX.Element {
      if (this.props.isPocSigned) {
        return (
          <section className={`${css("col h-100")} signatories-contracts`}>
            <SignatoriesBlock />
            {hasDocumentViewer(this.props.transaction, this.props.signatureWorkflow, this.props.step) &&
              <section>
                <ContractsReview />
                <OverallContractsReview />
              </section>
            }
          </section>);
      }
      return (
        <section className={css("col")}>
          <ContractsReview />
          <OverallContractsReview />
        </section>
      );
    }

    public timeToDisplayAlert() {
      setTimeout(() => this.closeAlert(), 25000);
    }

    private closeAlert() {
      this.isAlertDisplayed = false;
      const alert: any = document.getElementById("alert-sign");
      if (!!alert) {
        alert.style.display = "none";
      }
    }

    public renderAlertAfterPocSignature(): JSX.Element {
      if (this.props.isPocSigned && this.props.pocId && !this.props.transactionSigned &&
        this.props.transaction && this.props.transaction.contractType && this.props.transaction.contractType.type !== PROOF_OF_CONSENT) {
        this.isAlertDisplayed = true;
        this.timeToDisplayAlert();
        return (
          <div id="alert-sign"
            className={`${css("alert alert-success alert-dismissible fade show justify-content-center col-12")}
            ${css(this.isAlertDisplayed ? "" : "d-none")} custum_alert`} role="alert">
            <MarkupText id={this.props.language + ".sign-contract_modal.signature is possible"}>
              The signature of the proof of consent has been successful, please proceed the signature of the contract.
            </MarkupText>
            <button type="button" className={`${css("close")}`} data-dismiss="alert" aria-label="close"
              onClick={() => this.closeAlert()}>
              <em className={"material-icons"}>close</em>
            </button>
          </div>
        );
      }
      return <div />;
    }

    private setSignatureWorkflow(): void {
      if (this.props.transaction
        && this.props.transaction.contractType
        && this.props.transaction.contractType.exclusiveSignatureWorkflow) {
        try {
          const contractTypeSignatureWorkflow = this.props.transaction.contractType.exclusiveSignatureWorkflow;
          const signatureWorkflow = require(`../../signature-workflows/${contractTypeSignatureWorkflow}.json`);
          this.props.setSignatureWorkflow(signatureWorkflow);
        } catch (e) {
          console.error(e)
        }
      }
    }
    
    private renderContractName(
      signatureWorkflow: ISignatureWorkflow,
      transaction: ITransaction
    ): JSX.Element {
      return (
        <div>
          {signatureWorkflow.parameters.contractName && getContractName(transaction) &&
            <h1 ref={(elt: HTMLElement) => {
              this.contractName = elt;
            }}>{getContractName(transaction)}</h1>
          }
        </div>
      );
    }

    private renderWorkflowCheckBox(checkboxes: string[] | undefined, hasPagination = false): JSX.Element {
      return (
        <section className={`${css("row")} ${checkboxes ? "mt-2" : ""}`}>
          <section className={`${css("col-10 col-lg-auto")} ${checkboxes ? "consent-checkbox mb-4" : ""}`}>
            <Checkbox />
          </section>
          {hasPagination &&
            <PaginationComponent documentsCount={this.props.documentsCount}/>
          }
        </section>
      )
    }

    private renderSignatureWorkflow(): JSX.Element {
      const language = this.props.language
      const signatureWorkflow = this.state.signatureWorkflow;
      const transaction = this.props.transaction;
      if (signatureWorkflow) {
        if (this.isTransactionValid() && !isTransactionSigned(transaction) && !isTransactionExpired(transaction)) {
          const translatedJson = getSignatureWorkflowByLanguage(language, signatureWorkflow.languages);
          const currentStep = translatedJson[this.state.step];
          const phoneNumber = getCurrentSignatoryPhoneNumber(this.props.transaction, this.props.user);
          const isCollateralEnabled = transaction.spiderCollateralOption != null && currentStep.collateral;
          this.props.setOTPMode(getCurrentSignatoryOTPMethod(phoneNumber, this.props.isPublicSignatory));
          const email = this.props.user.username;
          return (
            <section>
              {this.renderContractName(signatureWorkflow, transaction)}
              <div>
                {currentStep.title &&
                  <h3>
                    {currentStep.title}
                  </h3>
                }

                <div className={"p-3"} style={"min-height: 30vh; background-color: #f7f9fa;"}>
                  <div className={css(currentStep.strong ? "mb-2" : "")}>
                    <strong>
                      {currentStep.strong}
                    </strong>
                  </div>

                  {currentStep.text && <WorkflowText />}

                  
                  {currentStep.documentViewer && 
                    <div style={"background-color: white;"}>
                      <DocumentViewerComponent 
                        transaction={this.props.transaction}
                        isPocSigned={this.props.isPocSigned}
                        language={this.props.language}
                        displayButtons={this.isTransactionValid()}
                        setDocumentContainer={(elt: any) => this.setDocumentContainer(elt)}
                      />
                    </div>
                  }

                  {currentStep.documentDownloadList && <WorkflowDownloadDocumentList />}

                  {currentStep.documentList && 
                    <section className={css("row mt-2")}>
                      <section className={css("col-1")}></section>
                      <section className={`${css("col-auto justify-content-start")} ${!isCollateralEnabled ? "mb-2" : ""}`}>
                        <WorkflowDocumentList />
                      </section>
                    </section>
                  }

                  {currentStep.bulletsText}
                  {currentStep.bullets && <WorkflowBullets />}

                  {(currentStep.checkbox || isCollateralEnabled) && 
                    this.renderWorkflowCheckBox(currentStep.checkbox, signatureWorkflow.parameters.pagination)
                  }

                  {currentStep.warning && currentStep.warning
                    .replace(/{{OTP}}/g, OtpMode[this.props.otpMode])
                    .replace(/{{OTPValue}}/g, getOtpValue(this.props.otpMode, phoneNumber, email))
                  }

                  {signatureWorkflow.parameters.signingButtons &&
                    <SignButtonsComponent
                      transaction={this.props.transaction}
                      language={this.props.language}
                      phoneNumber={this.props.phoneNumber}
                      signable={this.props.signable}
                      allDocumentsRead={this.state.allDocumentsRead}
                      isPublicSignatory={this.props.isPublicSignatory}
                      sign={(otpMethod: OtpMode, phoneNumberIsValid?: boolean) => this.sign(otpMethod, phoneNumberIsValid)}
                    />
                  }
                  {currentStep.cancelTransaction && 
                    <CancelSignatureComponent
                      language={this.props.language}
                      allDocumentsRead={this.state.allDocumentsRead}
                      reject={this.props.reject}
                    />
                  }
                  {currentStep.OTP && <Otp />}
                </div>
              </div>
              {signatureWorkflow.parameters.navigationButtons && <WorkflowPagination />}
            </section>
          )
        } else {
          return (
            <section class="h-100">
              {this.renderContractName(signatureWorkflow, transaction)}
              <DocumentViewerComponent 
                transaction={this.props.transaction}
                isPocSigned={this.props.isPocSigned}
                language={this.props.language}
                displayButtons={this.isTransactionValid()}
                setDocumentContainer={(elt: any) => this.setDocumentContainer(elt)}
              />
            </section>
          )
        }
      } else {
        return (<div></div>)
      }
    }

    private renderDefaultSignatureComponent(): JSX.Element {
      return (
        <section>
          <DocumentViewerComponent 
            transaction={this.props.transaction}
            isPocSigned={this.props.isPocSigned}
            language={this.props.language}
            displayButtons={this.isTransactionValid()}
            setDocumentContainer={(elt: any) => this.setDocumentContainer(elt)}
          />
          <SignatureComponent
            transaction={this.props.transaction}
            language={this.props.language}
            phoneNumber={this.props.phoneNumber}
            signable={this.props.signable}
            allDocumentsRead={this.state.allDocumentsRead}
            isPublicSignatory={this.props.isPublicSignatory}
            isDisplayed={this.isSignatureComponentDisplayed()}
            documentsCount={this.props.documentsCount}
            reject={this.props.reject}
            sign={(otpMethod: OtpMode, phoneNumberIsValid?: boolean) => this.sign(otpMethod, phoneNumberIsValid)}
          />
        </section>
      )
    }

    private canDisplayAlert(): boolean {
      return this.state.signatureWorkflow && this.props.transactionSigned;
    }

    private getAlertMessage(): { isSuccess: boolean, messageId: string } {
      return {
        isSuccess: true,
        messageId: this.props.language + ".signature.successfull-signature",
      }
    }

    public render(): JSX.Element {
      this.isPublicSignatory();
      this.setSignatureWorkflow();
      if (this.props.transaction || this.props.displayProofOfConsent) {
        return (
          <section
            className={`${css("container")} ${this.props.isOtpMandatory ? "signature-page-with-otp" : "signature-page-without-otp"}`}>
            <section className={css("row position-absolute offset-lg-3")}>
              {this.renderAlertAfterPocSignature()}
            </section>
            <Alert canDisplayAlert={this.canDisplayAlert()} alertMessage={this.getAlertMessage()} />
            <section className={`${css("row")} signatories-and-contract-review`}>
              {this.renderSignatoriesAndContractReview()}
              <section className={`${css("col-12 col-lg-9 mb-1 h-100")}`}>
                {this.state.signatureWorkflow != undefined
                  ? this.renderSignatureWorkflow()
                  : this.renderDefaultSignatureComponent()
                }
              </section>
            </section>
            {this.renderExpiredMessage()}
            {this.renderModals()}
            {this.renderComponentsForCorporateValidation()}
            {this.renderComponentsForSignatureWithoutOtp()}
            {this.renderIntermediateValidatorComponents()}
          </section>
        );
      }
      return <div />;
    }
  });

function mapStateToProps(state: ApplicationState) {
  return {
    allDocumentsRead: state.signatureState.allDocumentsRead,
    documentsCount: state.documentState.documents ? state.documentState.documents.length : 0,
    enableFullscreen: state.documentState.fullscreenMode === FullscreenMode.ON,
    isValidSignatory: state.userState.isValidSignatory,
    language: state.languageState.language,
    phoneNumber: state.userState.phoneNumber,
    signable: state.signatureState.signable,
    signatureProcessing: state.signatureState.processing,
    transactionSigned: state.signatureState.signed,
    isPocSigned: state.pocState.isPocSigned,
    pocId: state.pocState.pocId,
    transaction: state.transactionState.transaction,
    isValidValidator: state.userState.isValidValidator,
    isValidIntermediateValidator: state.userState.isValidIntermediateValidator,
    isTransactionRejected: state.intermediateValidatorState.rejected,
    isOtpMandatory: state.userState.isOtpMandatory,
    consent: state.signatureState.consent,
    consents: state.signatureState.consents,
    displayProofOfConsent: state.pocState.forceDisplay,
    step: state.signatureState.step,
    signatureWorkflow: state.signatureState.signatureWorkflow,
    isPublicSignatory: state.signatureState.isPublicSignatory,
    otpMode: state.signatureState.otpMode,
    user: state.userState.user,
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  exitFullscreen: () => dispatch(exitFullscreenMode()),
  reject: () => dispatch(reject()),
  rejectByIntermediateValidator: () => dispatch(rejectContract()),
  forwardToSignatories: () => dispatch(forwardToSignatories()),
  sign: (otpMode: OtpMode, phoneNumberIsValid: boolean) => dispatch(sign(otpMode, phoneNumberIsValid)),
  confirmValidation: (open: boolean, isConfirmed: boolean) => dispatch(confirmValidation(open, isConfirmed)),
  signWithoutOtp: (isSigning: boolean) => dispatch(signWithoutOtp(isSigning)),
  errorConsent: (isInError: boolean) => dispatch(errorConsent(isInError)),
  setSignatureWorkflow: (signatureWorkflow: ISignatureWorkflow) => dispatch(setSignatureWorkflow(signatureWorkflow)),
  updateStep: (step: number) => dispatch(updateStep(step)),
  initWorkflow: () => dispatch(initWorkflow()),
  setIsPublicSignatory: (isPublicSignatory: boolean) => dispatch(setIsPublicSignatory(isPublicSignatory)),
  setOTPMode: (otpMode: OtpMode) => dispatch(setOTPMode(otpMode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignaturePage as any);
