import { IWidgetConfigurationProps, withWidgetConfiguration, } from '@sgwt-widget/core';
import { Component, h } from 'preact';
import { MarkupText } from 'preact-i18n';
import { ICounterpartSignatory } from '../../../common/domain/ICounterpartSignatory';
import { css } from '../../styles';

export interface ISignatoryFormCardProps {
  language?: string;
  handleUpdateSignatory: (lonesomeField: { [index: string]: string }) => void;
  handleDeletion: () => void;
  signatory: ICounterpartSignatory;
}

const SignatoryFormCard = withWidgetConfiguration(
  class extends Component<ISignatoryFormCardProps & IWidgetConfigurationProps> {

    constructor(props: ISignatoryFormCardProps & IWidgetConfigurationProps) {
      super(props);
      this.removeSignatory = this.removeSignatory.bind(this);
    }

    private updateSignatory(event: Event) {
      const target = (event.target as any);
      this.props.handleUpdateSignatory({ [target.name]: target.value });
    }

    private removeSignatory() {
      this.props.handleDeletion();
    }

    public render(): JSX.Element {
      const signatory = this.props.signatory;
      const label = "form-group mb-2 w-100 font-weight-bold";
      const formControl = "form-control";
      return (
        <article className={css('card card-bordered mb-3 mr-3')} style="max-width: 450px">
          <section className={css('card-header d-flex justify-content-between')}>
            <h4>
              <MarkupText id={this.props.language + '.signatory-form-card.card-title'}>Authorized signatory</MarkupText>
            </h4>
            <button type="button" className={css('btn btn-outline-primary btn-sm')}
                    onClick={this.removeSignatory}>
              <em className={`${css('icon-sm')} material-icons icon`}>delete</em>
            </button>
          </section>

          <section className={css('card-body pt-0')}>
            <label className={css(label)}>
              <MarkupText id={this.props.language + '.signatory-form-card.firstname'}>First name</MarkupText>
              <input type="text" className={css(formControl)} name="firstname"
                     value={signatory.firstname}
                     onChange={this.updateSignatory.bind(this)}/>
            </label>

            <label className={css(label)}>
              <MarkupText id={this.props.language + '.signatory-form-card.lastname'}>Last name</MarkupText>
              <input type="text" className={css(formControl)} name="lastname"
                     value={signatory.lastname}
                     onChange={this.updateSignatory.bind(this)}/>
            </label>
            <label className={css(label)}>
              <MarkupText id={this.props.language + '.signatory-form-card.email address'}>Email address</MarkupText>
              <input type="text" className={css(formControl)} name="identifier"
                     value={signatory.identifier}
                     onChange={this.updateSignatory.bind(this)}/>
            </label>
            <label className={css(label)}>
              <MarkupText id={this.props.language + '.signatory-form-card.mobile phone number'}>Mobile phone number</MarkupText>
              <input type="text" className={css(formControl)} name="phoneNumber"
                     value={signatory.phoneNumber}
                     onChange={this.updateSignatory.bind(this)}/>
            </label>
          </section>
        </article>);
    }
  }
);

export default (SignatoryFormCard as any);
