import "./FileSelector.scss";

import {
  IWidgetConfigurationProps,
  WidgetConfiguration,
  withWidgetConfiguration,
} from "@sgwt-widget/core";
import { Component, h } from "preact";
import { MarkupText } from "preact-i18n";
import { connect } from "preact-redux";
import { Action } from "redux";
import { ITransactionDocument } from "../../../common/domain/TransactionDocument";
import { readDocument, readDocumentCompletion } from "../../actions/IDocumentAction";
import { ApplicationState } from "../../states/ApplicationState";
import { css } from "../../styles";
import { ITransaction } from "../../../common/domain/ITransaction";
import { selectDocument } from "../../../common/helpers/documentHelper";

interface IFileSelectorState {
  documents: ITransactionDocument[];
  currentDocumentIndex: number;
  isDropdownOpen: boolean;
  newPocId: number;
  focusOnDocument: string;
  transactionId: number;
}

export interface IFileSelectorProps {
  currentDocumentId: number;
  transaction: ITransaction;
  documents: ITransactionDocument[];
  language: string;
  isPocSigned: boolean;
  pocId: number;
  focusOnDocument: string;
  readDocument: (documentId: number, documentUrl: string) => void;
  readDocumentCompletion: (documentId: number, completion: number) => void;
}

const FileSelector = withWidgetConfiguration(
  class extends Component<IFileSelectorProps & IWidgetConfigurationProps,
    IFileSelectorState> {

    public async componentDidMount() {
      this.setState({
        currentDocumentIndex: 0,
        documents: this.props.documents,
        focusOnDocument: this.props.focusOnDocument,
        isDropdownOpen: false,
      });
      if (this.props.transaction) {
        this.setState({ transactionId: this.props.transaction.id });
        this.initFirstDocument();
      }
    }

    public newDocumentsAreSame(documents: ITransactionDocument[]): boolean {
      const ids = documents.map((doc: ITransactionDocument) => doc.id);
      const currentIds = this.state.documents.map(
        (doc: ITransactionDocument) => doc.id
      );
      return (
        ids.length === currentIds.length &&
        currentIds.every((v, i) => v === ids[i])
      );
    }

    public async componentWillReceiveProps(nextProps: IFileSelectorProps) {
      this.setState({
        newPocId: nextProps.pocId
      });
      if (nextProps.transaction && this.state.transactionId !== nextProps.transaction.id) {
        this.setState({ transactionId: nextProps.transaction.id });
      }
      if (nextProps.documents && !this.newDocumentsAreSame(nextProps.documents)) {
        this.setState({
          currentDocumentIndex: 0,
          documents: nextProps.documents,
          isDropdownOpen: false,
        });

        this.initFirstDocument();
      }
      if (nextProps.currentDocumentId) {
        this.setState({
          currentDocumentIndex: this.state.documents.findIndex(
            (doc: ITransactionDocument) => doc.id === nextProps.currentDocumentId
          ),
        });
      }
    }

    public initFirstDocument() {
      if (this.state.documents && this.state.documents.length > 0) {
        let document: ITransactionDocument | undefined;
        if (this.props.focusOnDocument) {
          document = this.state.documents.find(d => d.filename === this.props.focusOnDocument)
        }

        this.onSelectDocument(
          document ? document : this.state.documents[this.state.currentDocumentIndex],
          this.props.pocId,
          this.props.isPocSigned,
          this.props.transaction,
          this.props.widgetConfiguration,
          this.props.readDocument,
          this.props.readDocumentCompletion
        );
      }
    }

    public async onSelectDocument(
      document: ITransactionDocument,
      pocId: number, 
      isPocSigned: boolean, 
      transaction: ITransaction,
      widgetConfiguration: WidgetConfiguration,
      readDocument: (documentId: number, documentUrl: string) => void,
      readDocumentCompletion: (documentId: number, completion: number) => void,
    ) {
      selectDocument(
        document,
        pocId,
        isPocSigned,
        transaction,
        widgetConfiguration,
        readDocument,
        readDocumentCompletion
      );
      this.setState({
        currentDocumentIndex: this.state.documents.indexOf(document),
        isDropdownOpen: false,
      });
    }

    public toggleDropdown() {
      this.setState({
        isDropdownOpen: !this.state.isDropdownOpen,
      });
    }

    public closeDropdown() {
      this.setState({
        isDropdownOpen: false,
      });
    }

    private checkTabPressedAndCloseDropdown = (event: KeyboardEvent) => {
      if ("Tab" === event.key && false === event.shiftKey) {
        this.closeDropdown();
      }
    }

    public renderDocumentNames(): JSX.Element[] {
      let timer;
      if (this.state.isDropdownOpen === true) {
        clearTimeout(timer);
        timer = setTimeout(() => {
          if (this.state.isDropdownOpen === true) { this.toggleDropdown(); }
        }, 10000);
      }

      return this.state.documents.map((doc: ITransactionDocument, i: number, { length }) => (
        <button
          className={`${css("dropdown-item")} pointer`}
          onClick={() => this.onSelectDocument(doc,this.props.pocId,
            this.props.isPocSigned,
            this.props.transaction,
            this.props.widgetConfiguration,
            this.props.readDocument,
            this.props.readDocumentCompletion)}
          onKeyDown={(i + 1 === length) ? this.checkTabPressedAndCloseDropdown : undefined}
        >
          {doc.filename}
        </button>
      ));
    }

    public renderDropdownButton(): JSX.Element {
      if (this.state.documents[this.state.currentDocumentIndex]) {
        return (
          <article
            className={css(
              `dropdown justify-content-left justify-content-lg ${this.state.isDropdownOpen ? "show" : ""}`
            )}
          >
            <button
              className={css("btn btn-lg btn-flat-primary")}
              data-toggle={css("dropdown")}
              type="button"
              id="documentDropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={() => this.toggleDropdown()}
              onFocus={() => this.closeDropdown()}
            >
              <em className={`material-icons ${css("icon-sm")}`}> insert_drive_file </em>
              <span
                className={`${css("d-inline-block text-truncate")} filename`}>{this.state.documents[this.state.currentDocumentIndex].filename}</span>
              <em className={`material-icons ${css("icon-sm")}`}> arrow_drop_down </em>
            </button>
            <div
              aria-labelledby="documentDropdown"
              className={css(
                `dropdown-menu ${this.state.isDropdownOpen ? "show" : ""}`
              )}
              x-placement="bottom-start"
            >
              {this.renderDocumentNames()}
            </div>
          </article>
        );
      }
      return (
        <article
          className={css(`dropdown d-flex justify-content-center justify-content-lg ${this.state.isDropdownOpen ? "show" : ""}`)}
        >
          <button
            type="button"
            className={css("btn btn-flat-primary dropdown-toggle")}
            data-toggle={css("dropdown")}
            aria-haspopup="true"
            aria-expanded="false"
            onClick={() => this.toggleDropdown()}
          >
            <MarkupText id={this.props.language + ".file-selector.loading"}>
              Loading...
            </MarkupText>
          </button>
        </article>
      );
    }

    public render(): JSX.Element {
      return this.state.documents && this.state.documents.length > 0 ? (
        this.renderDropdownButton()
      ) : (
        <article />
      );
    }
  }
);

function mapStateToProps(state: ApplicationState) {
  return {
    currentDocumentId: state.documentState.currentDocumentId,
    documents: state.documentState.documents,
    focusOnDocument: state.focusOnDocumentState.focusOnDocument,
    language: state.languageState.language,
    transaction: state.transactionState.transaction,
    pocId: state.pocState.pocId,
    isPocSigned: state.pocState.isPocSigned,
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  readDocument: (documentId: number, documentUrl: string) =>
    dispatch(readDocument(documentId, documentUrl)),
  readDocumentCompletion: (documentId: number, completion: number) =>
    dispatch(readDocumentCompletion(documentId, completion)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileSelector as any);
