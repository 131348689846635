import { AjaxRequest } from "rxjs/Rx";
import { Observable } from "rxjs";
import { AjaxResponse } from "rxjs/observable/dom/AjaxObservable";
import { WidgetConfiguration } from "@sgwt-widget/core";

const REST = "/rest";
const REST_PUBLIC = "/rest/public";

function getUrl(urlOrRequestUrl: string): string {
  if (!urlOrRequestUrl.includes("/v2/transactions")) {
    return urlOrRequestUrl.replace(REST, REST_PUBLIC).replace("/v2", REST_PUBLIC).replace("transactions", "transaction");
  }
  
  return urlOrRequestUrl.replace("/v2", "/v2/public");
}

export function ajaxCall(urlOrRequest: AjaxRequest | string, widgetConfiguration: WidgetConfiguration): Observable<AjaxResponse> {
  const currentUrl: string = window.location.href;
  if (currentUrl.includes('/p/')) {
    const urlParam = currentUrl.split('/p/');
    const token = urlParam.length > 0 ? urlParam[1].split('/')[0] : null;
    if (typeof urlOrRequest === "string") {
      return Observable.ajax({
        url: urlOrRequest.replace(REST, REST_PUBLIC),
        headers: { 'token': token }
      });
    }
    
    urlOrRequest.headers = { ...urlOrRequest.headers, Token: token } as object;

    if (urlOrRequest.url) {
      urlOrRequest.url = getUrl(urlOrRequest.url);
    }

    return Observable.ajax(urlOrRequest);

  } 
  let bearerToken = '';
  const getBearerFromWidgetConfigurationBus = widgetConfiguration.bus.subscribe<string>(
    'sg-connect.access-token',
    async (bearer: string | undefined) => {
      if (bearer) {
        bearerToken = bearer;
      }
    }
  );
  if (bearerToken) {
    widgetConfiguration.bus.unsubscribe(getBearerFromWidgetConfigurationBus);
  }
  if (typeof urlOrRequest === "string") {
    return Observable.ajax({
      url: urlOrRequest,
      headers: {
        'Authorization': bearerToken
      }
    });
  }
  
  urlOrRequest.headers = { ...urlOrRequest.headers, 'Authorization': bearerToken } as object;
  return Observable.ajax(urlOrRequest);
}
